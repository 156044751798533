import React from 'react';
import { Page } from '../src/js/types/Page';
import CMSLayout from '../src/js/layout/CMS/CMSLayout';
import { Title } from '../src/js/components/Title';

const Index: Page = function () {
    return (
        <div className="h-100 position-relative">
            <Title name="Home"></Title>
            <h1 className="font-title display-4">SELAMAT DATANG!</h1>

            <img className="img-fluid position-absolute bottom-0" src="/lineup.png" alt="Lineup" />
        </div>
    );
}

Index.layout = CMSLayout;
export default Index;
